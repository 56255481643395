import { ReactNode } from "react";

export default function AuthTitle({ children }: { children: ReactNode }) {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md mb-6 sm:mb-10">
      <h2 id="signup-header" className="text-center text-2xl font-semibold tracking-tight text-white text-shadow">
        {children}
      </h2>
    </div>
  );
}
